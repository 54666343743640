import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { NextPage } from "next";
import Link from "next/link";

const Custom404: NextPage = () => (
  <Container
    sx={{
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      padding: 2,
    }}
  >
    <Typography variant="h2" color="textSecondary" gutterBottom>
      {`404 - Page Not Found`}
    </Typography>
    <Typography variant="body1" color="textSecondary" gutterBottom>
      {`It seems you've ended up in the wrong place. Let's get you back home.`}
    </Typography>
    <Button variant="contained" color="primary" component={Link} href="/">
      {`Go Home`}
    </Button>
  </Container>
);

export default Custom404;
